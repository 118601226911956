/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Na początku 2024 roku rozpoczęły się prace nad kolejną częścią zadania inwestycyjnego pod nazwą „Rozbudowa drogi powiatowej 1507C Włóki – Jarużyn – Bydgoszcz na odcinku Włóki- Gądecz”. Rozbudową został objęty odcinek od km 1+775 do km 2+675."), "\n", React.createElement(_components.p, null, "W ramach prac budowlanych zostały wykonane między innymi następujące zadania:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "rozbiórki istniejących konstrukcji jezdni o nawierzchni asfaltowej, zjazdów z kostki betonowej oraz nawierzchni betonowej, kolidujących z inwestycją ogrodzeń,"), "\n", React.createElement(_components.li, null, "wykonanie nowej jezdni o nawierzchni z betonu asfaltowego,"), "\n", React.createElement(_components.li, null, "budowę zatoki autobusowej o nawierzchni betonowej, zjazdów o nawierzchni z betonu asfaltowego oraz z kostki betonowej, poboczy gruntowych, chodników,"), "\n", React.createElement(_components.li, null, "budowę drogi rowerowej o nawierzchni bitumicznej,"), "\n", React.createElement(_components.li, null, "rozbiórkę i przebudowę przepustów, budowę kanalizacji deszczowej, przebudowę sieci wod.-kan., przebudowę sieci energetycznych i oświetlenia,"), "\n", React.createElement(_components.li, null, "wykonanie nasadzeń zastępczych,"), "\n", React.createElement(_components.li, null, "wykonanie zieleni w postaci trawników,"), "\n", React.createElement(_components.li, null, "wykonanie - dla poprawy bezpieczeństwa pieszych i rowerzystów, na przejściach dla pieszych - oświetlenia ulicznego LED, oraz znaków informacyjnych na fluorescencyjnym tle oraz płytki wskaźnikowe/ostrzegawcze."), "\n"), "\n", React.createElement(_components.p, null, "Termin realizacji przewidywany był na 10 miesięcy. Inwestycja została oddana do użytku w lipcu 2024."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
